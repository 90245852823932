import React, { useMemo } from 'react';

import { Accordion, AccordionItemType } from 'shared/components/Accordion/Accordion';
import { AgreementPreviewLink, AgreementType } from 'api/types';
import { useTheme } from 'styled-components';
import { AgreementsMap } from './AgreementsMap';
import {
  AgreementTpvBannerContent,
  AgreementTpvBannerIconWrapper,
  AgreementTpvBannerStyled,
  FlexContainer,
  FlexRow,
} from './AgreementsAccordion.styles';
import { AnswerNetVideoTPVStatusType } from '../../../../../modules/types';
import { StatusPill } from '../../../../../shared/components/StatusPill/StatusPill';
import { BannerText } from '../../../../../shared/components/Banner/Banner.styles';
import { CircleInfoIcon } from '../../../../../shared/components/icons';
import { Button } from '../../../../../shared/components/Button/Button';

type Props = {
  userId: string;
  agreements: AgreementType[];
  onChangeArchiveAgreement: (agreement: AgreementType) => void;
  onResetAgreementTpv: (agreement: AgreementType) => void;
  onFetchPreviewVideoLinks: (agreement: AgreementType) => Promise<AgreementPreviewLink[]>;
};
export function AgreementsAccordion({
  userId,
  agreements,
  onChangeArchiveAgreement,
  onResetAgreementTpv,
  onFetchPreviewVideoLinks,
}: Props) {
  const theme = useTheme();

  const items = useMemo(
    (): AccordionItemType[] =>
      agreements.map((agreement) => {
        const isTpvRefreshedNeeded =
          agreement.users_tpv_statuses.find((item) => item.user_id === userId)?.tpv_status ===
          AnswerNetVideoTPVStatusType.FAILED;
        return {
          label: (
            <FlexRow>
              <div>Agreement #{agreement.homeowner_contract_id}</div>
              {isTpvRefreshedNeeded && (
                <StatusPill
                  text="TPV Refreshed needed"
                  bgColor="backgroundSystemErrorFunction120"
                />
              )}
            </FlexRow>
          ),
          id: agreement.id,
          content: (
            <FlexContainer>
              {isTpvRefreshedNeeded && (
                <AgreementTpvBannerStyled $fitContent={false}>
                  <AgreementTpvBannerIconWrapper>
                    <CircleInfoIcon
                      color={theme.colors.iconIconPrimarySecondary1100}
                      size="small"
                    />
                  </AgreementTpvBannerIconWrapper>
                  <AgreementTpvBannerContent>
                    <BannerText as="p">
                      <strong>Third-party verification </strong> has failed
                    </BannerText>
                    <Button
                      styleVariant="tertiary"
                      label="Reset Verification"
                      onClick={() => {
                        onResetAgreementTpv(agreement);
                      }}
                    />
                  </AgreementTpvBannerContent>
                </AgreementTpvBannerStyled>
              )}
              <AgreementsMap
                agreement={agreement}
                onChangeArchiveAgreement={onChangeArchiveAgreement}
                onFetchPreviewVideoLinks={onFetchPreviewVideoLinks}
              />
            </FlexContainer>
          ),
        };
      }),
    [
      agreements,
      onChangeArchiveAgreement,
      onFetchPreviewVideoLinks,
      onResetAgreementTpv,
      theme.colors.iconIconPrimarySecondary1100,
      userId,
    ],
  );

  return <Accordion items={items} styleVariant="tertiary" size="large" />;
}

export default AgreementsAccordion;
