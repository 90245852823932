import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { isBefore, startOfDay, startOfToday } from 'date-fns';
import { format, toZonedTime } from 'date-fns-tz';
import { FullStory } from '@fullstory/browser';

import { ContractType, DateRangeZoomLevel, FlagKey } from 'api/types';
import { createDateRange, DateRange, DateRangeStartDateBehavior } from 'api/system/utils/DateRange';
import { SystemCategory } from 'api/system/monitoringTypes';
import { LINKS } from 'shared/links';
import { useUser } from 'hooks/useUser';
import { pages } from 'pages';
import { Header } from 'shared/components/Header/Header';
import { ChartWrapper } from 'modules/system/components/Charts/Chart.styles';
import { getMonitoringSystemInfo } from 'modules/system/utils/getMonitoringSystemInfo';
import { useGetSiteTimezone, useGetSolarEnergyWithEnergyAllocation } from 'api/system';
import { TimescaleEnum } from 'modules/system/components/DateSelector/utils/showArrows';
import { WrenchIcon } from 'shared/components/icons/WrenchIcon';
import { GearIcon } from 'shared/components/icons/GearIcon';
import { ManageCard } from 'modules/overview/components/ManageCard/ManageCard';
import { getDataProvider } from 'modules/system/utils/ChartDataProviders/getDataProvider';
import { timezoneValidateName } from 'modules/system/utils/timezoneValidateName';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { getCategoriesEnabled } from 'modules/system/utils/getCategoriesEnabled';
import { isSystemCategoriesFeatureEnabled } from 'modules/system/utils/isSystemCategoriesFeatureEnabled';
import { SunIcon } from 'shared/components/icons/SunIcon';
import { BatteryBoltIcon } from 'shared/components/icons/BatteryBoltIcon';
import { ElecticPoleIcon } from 'shared/components/icons/ElecticPoleIcon';
import { HomeIcon } from 'shared/components/icons/HomeIcon';
import { TabControls, Tab } from 'shared/components/TabControls/TabControls';
import {
  ChartAndSelectors,
  OmnidianHardwareWrapper,
  SystemChartHeader,
  SystemPageBodyWrapper,
  SystemPageWrapper,
  SystemSideCards,
} from './SystemPage.styles';
import { DateSelector } from '../../components/DateSelector/DateSelector';
import { TimescaleControl } from '../../components/TimescaleControl/TimescaleControl';
import { MonitoringSnapshot } from '../../components/MonitoringSnapshot/MonitoringSnapshot';
import { getZoomLevelFromLocalTimescale } from '../../utils/getZoomLevelFromLocalTimescale';
import { DayChart } from '../../components/Charts/DayChart/DayChart';
import { EnergyChart } from '../../components/Charts/EnergyChart/EnergyChart';
import { SystemCard } from '../../../overview/components/SystemCard/SystemCard';

export function SystemPage() {
  const { selectedAgreement } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { system, inServiceDate } = getMonitoringSystemInfo(selectedAgreement);

  const {
    flags: {
      [FlagKey.systemCategories]: systemCategoriesFetureFlagEnabled,
      [FlagKey.systemCategoriesBattery]: battery,
      [FlagKey.systemCategoriesGrid]: grid,
      [FlagKey.systemCategoriesHome]: home,
      [FlagKey.systemCategoriesSolar]: solar,
    },
  } = useFeatureFlags();

  const categoriesEnabled = getCategoriesEnabled({ battery, grid, home, solar, system });

  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [dateRanges, setDateRanges] = useState<Partial<Record<DateRangeZoomLevel, DateRange>>>({});
  const [hasChartError, setHasChartError] = useState<boolean>(false);
  const [siteTimezone, setSiteTimezone] = useState<string>();
  const [category, setCategory] = useState<SystemCategory>(
    (categoriesEnabled && categoriesEnabled[0]) || SystemCategory.SOLAR,
  );

  const { data: systemMetadata, isError: timezoneError } = useGetSiteTimezone(system);

  const { data: solarEnergyAllocationData } = useGetSolarEnergyWithEnergyAllocation({
    systemId: system?.id,
    dateRange: dateRange ? { ...dateRange, zoomLevel: DateRangeZoomLevel.WEEK } : undefined, // Use WEEK for zoom level, so that `aggregation_level=day` is used
  });

  // set local timescale labels
  const localTimescales = {
    day: t('datePicker.day'),
    week: t('datePicker.week'),
    month: t('datePicker.month'),
    year: t('datePicker.year'),
  };

  const [selectedTimescale, setSelectedTimescale] = useState(localTimescales.day);

  const dataProvider =
    system && dateRange && getDataProvider(system, category, dateRange.zoomLevel, t);

  // get "today" for system
  const today = siteTimezone ? toZonedTime(startOfToday(), siteTimezone) : startOfToday();

  // set initial dateRange information with today on mount
  useEffect(
    () => {
      if (system?.site_timezone || systemMetadata) {
        try {
          setHasChartError(false);
          const timezoneName = timezoneValidateName(
            system?.site_timezone || systemMetadata?.timezone || '',
          );

          setSiteTimezone(timezoneName);

          const zonedTime = toZonedTime(new Date(), timezoneName);
          const midnightToday = startOfDay(zonedTime);
          const formatted = format(midnightToday, 'yyyy-MM-dd HH:mm:ssXXX', {
            timeZone: timezoneName,
          });
          const startDate = isBefore(new Date(formatted), midnightToday)
            ? midnightToday
            : new Date(formatted);

          const newDateRange = createDateRange(
            startDate,
            DateRangeZoomLevel.DAY,
            timezoneName,
            DateRangeStartDateBehavior.FROM_CALENDAR_UNIT,
          );

          setDateRange(newDateRange);
          setDateRanges({
            ...dateRanges,
            ...{
              [DateRangeZoomLevel.DAY]: newDateRange || undefined,
            },
          });
        } catch {
          setHasChartError(true);
        }
      }
    },
    // disabling because we only want this to run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [systemMetadata, system?.site_timezone],
  );

  // if no systems have monitoring go to hardware details page
  useEffect(() => {
    if (selectedAgreement && !system) {
      navigate(pages.HARDWARE_INFO, { state: { system: selectedAgreement?.system } });
    }
  }, [selectedAgreement, system, navigate]);

  const handleDateChange = (date: Date) => {
    if (!dateRange || !siteTimezone) {
      return;
    }

    const newDateRange = createDateRange(
      date,
      dateRange.zoomLevel,
      siteTimezone,
      DateRangeStartDateBehavior.FROM_DATE,
    );
    setDateRange(newDateRange);
    setDateRanges({ ...dateRanges, ...{ [dateRange.zoomLevel]: newDateRange } });
  };

  const handleTimescaleChange = (timescale: string) => {
    setSelectedTimescale(timescale);
    const zoomLevel = getZoomLevelFromLocalTimescale(timescale, localTimescales);
    const existingDateRange = dateRanges[zoomLevel];
    if (existingDateRange) {
      setDateRange(existingDateRange);
    } else {
      const newDateRange = createDateRange(
        siteTimezone ? toZonedTime(new Date(), siteTimezone) : new Date(),
        zoomLevel,
        siteTimezone,
        DateRangeStartDateBehavior.FROM_CALENDAR_UNIT,
      );
      setDateRange(newDateRange);
      setDateRanges({
        ...dateRanges,
        ...{ [zoomLevel]: newDateRange },
      });
    }
  };

  const handleHasChartError = (hasError: boolean) => {
    setHasChartError(hasError);
  };

  const handleOmnidianClick = () => {
    FullStory('trackEvent', { name: 'Omnidian Card Clicked', properties: { page: 'System' } });
  };

  const showOmnidianCard =
    selectedAgreement?.contract_type === ContractType.PPA ||
    selectedAgreement?.contract_type === ContractType.LSE;

  let timescaleMapped = TimescaleEnum.DAY;
  if (selectedTimescale === localTimescales.week) {
    timescaleMapped = TimescaleEnum.WEEK;
  } else if (selectedTimescale === localTimescales.month) {
    timescaleMapped = TimescaleEnum.MONTH;
  } else if (selectedTimescale === localTimescales.year) {
    timescaleMapped = TimescaleEnum.YEAR;
  }

  const systemCategoriesEnabled = isSystemCategoriesFeatureEnabled({
    flag: systemCategoriesFetureFlagEnabled,
    inverter_manufacturer_id: system?.inverter_manufacturer_id,
  });

  const solarEnabled = solar && categoriesEnabled.includes(SystemCategory.SOLAR);
  const batteryEnabled = battery && categoriesEnabled.includes(SystemCategory.BATTERY);
  const gridEnabled = grid && categoriesEnabled.includes(SystemCategory.GRID);
  const homeEnabled = home && categoriesEnabled.includes(SystemCategory.HOME);

  const allTabs: (Tab | null)[] = [
    solarEnabled
      ? {
          label: 'Solar',
          Icon: SunIcon,
          styleVariant: 'blue',
          isActive: category === SystemCategory.SOLAR,
          onClick: () => {
            setCategory(SystemCategory.SOLAR);
          },
        }
      : null,
    batteryEnabled
      ? {
          label: 'Battery',
          Icon: BatteryBoltIcon,
          styleVariant: 'green',
          isActive: category === SystemCategory.BATTERY,
          onClick: () => {
            setCategory(SystemCategory.BATTERY);
          },
        }
      : null,
    gridEnabled
      ? {
          label: 'Grid',
          Icon: ElecticPoleIcon,
          styleVariant: 'magenta',
          isActive: category === SystemCategory.GRID,
          onClick: () => {
            setCategory(SystemCategory.GRID);
          },
        }
      : null,
    homeEnabled
      ? {
          label: 'Home',
          Icon: HomeIcon,
          styleVariant: 'dusk',
          isActive: category === SystemCategory.HOME,
          onClick: () => {
            setCategory(SystemCategory.HOME);
          },
        }
      : null,
  ];

  const filteredTabs = allTabs.filter((tab): tab is Tab => tab !== null);

  return (
    <SystemPageWrapper>
      <Header pageName="SYSTEM" title={t('system.title')} />

      {!hasChartError && <MonitoringSnapshot system={system} />}

      {system && (
        <SystemPageBodyWrapper>
          {dateRange && inServiceDate && (
            <ChartAndSelectors>
              <SystemChartHeader>
                <TimescaleControl
                  timescaleOptions={localTimescales}
                  activeTimescale={selectedTimescale}
                  handleSetTimescale={handleTimescaleChange}
                />

                <DateSelector
                  timescaleOptions={localTimescales}
                  timescale={selectedTimescale}
                  timescaleEnum={timescaleMapped}
                  currentDate={today}
                  selectedDate={dateRange.startDate}
                  onDateChange={handleDateChange}
                  dataRange={{ oldest: dateRange.startDate, newest: dateRange.endDate }}
                  inServiceDate={inServiceDate}
                />
              </SystemChartHeader>

              <ChartWrapper>
                {selectedTimescale === localTimescales.day && dataProvider && (
                  <DayChart
                    dateRange={dateRange}
                    system={system}
                    category={category}
                    dataProvider={dataProvider}
                    isTimezoneError={timezoneError}
                    onHasChartError={handleHasChartError}
                  />
                )}
                {selectedTimescale !== localTimescales.day && dataProvider && (
                  <EnergyChart
                    dataProvider={dataProvider}
                    dateRange={dateRange}
                    system={system}
                    category={category}
                    isTimezoneError={timezoneError}
                    onHasChartError={handleHasChartError}
                  />
                )}
                {systemCategoriesEnabled && filteredTabs.length > 1 && (
                  <TabControls tabs={filteredTabs} />
                )}
              </ChartWrapper>
            </ChartAndSelectors>
          )}

          <SystemSideCards $isChartPresent={Boolean(dateRange && inServiceDate)}>
            <SystemCard
              system={system}
              category={category}
              energyAllocation={solarEnergyAllocationData?.energy_allocation}
              monitoringData={solarEnergyAllocationData?.monitoring_data}
              routeToSystemPage={false}
            />

            <OmnidianHardwareWrapper>
              {showOmnidianCard && (
                <ManageCard
                  title={t('system.omnidianSystemService.title')}
                  subHeader={t('system.omnidianSystemService.copy')}
                  url={LINKS.EVERBRIGHT_OMNIDIAN_SERVICE_REQUEST}
                  onClick={handleOmnidianClick}
                  includeUrlArrow
                  iconElement={<WrenchIcon />}
                />
              )}

              <ManageCard
                title={t('system.hardwareDetails.title')}
                subHeader={t('system.hardwareDetails.copy')}
                route={pages.HARDWARE_INFO}
                navState={{ system }}
                iconElement={<GearIcon />}
              />
            </OmnidianHardwareWrapper>
          </SystemSideCards>
        </SystemPageBodyWrapper>
      )}
    </SystemPageWrapper>
  );
}

export default SystemPage;
